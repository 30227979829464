import { env, WHITELISTING_ENABLED } from "@/config/env";
import { externalLinks } from "@/constants/links";

const isWaitlistEnabled = WHITELISTING_ENABLED && env.WAITLIST_URL;

export const LINKS = [
  {
    title: "Pricing",
    url: "/pricing",
    newTab: false,
  },
  {
    title: "Resources",
    url: "",
    children: [
      {
        title: "View Docs",
        url: externalLinks.documentation,
        newTab: true,
        icon: null,
      },
      {
        title: "Blog",
        url: externalLinks.blog,
        newTab: true,
        icon: null,
      },
    ],
  },
  {
    title: "Plugins",
    url: "",
    children: [
      {
        title: "Wordpress",
        url: `${externalLinks.wordpressLink}?v=${new Date().getTime()}`,
        newTab: true,
        icon: "download",
      },
    ],
  },
  {
    title: "About",
    url: "/about",
    newTab: false,
  },
  {
    title: "Contact",
    url: "/contact",
    newTab: false,
  },
];

export const BUTTONS: {
  title: string;
  url: string;
  primary?: boolean;
}[] = [
  {
    title: "Launch App",
    url: env.DASHBOARD_LOG_IN_URL,
  },
];

export type NavProps = {
  title: string;
  url: string;
  newTab?: boolean;
  children?: NavProps[];
};
